"use client";
import useClientContext from "@/context/ClientContext";
import PublicIcons from '@/components/basic/PublicIcon';
import React, {useMemo} from 'react';
import ErrorPageLayout from '@/modules/home/components/ErrorPageLayout';

const Icon = () => {
  const { isMobileSize } = useClientContext();
  return <PublicIcons name="image404" width={isMobileSize ? 200 : 256} height={isMobileSize ? 200 : 256} />;
};

interface NotFoundProps {
  meta: any;
}

export default function NotFound(props: NotFoundProps) {
  const { meta } = props;
  const { translate } = useClientContext();

  const hideLogo = useMemo(() => meta === 'product' || meta === 'profile', [meta]);

  return (
    <ErrorPageLayout
      meta={meta}
      hideLogo={false}
      icon={Icon}
      title={translate("Page not found")}
      subtitle={translate("The page you referred to does not exist or has been deleted")}
    />
  );
}
